<template>
  <div class="card" no-body>
    <b-card-header header-tag="header" class="p-0" role="tab">
      <b-button
        block
        v-b-toggle="`collapsed-item-${index}`"
        variant="primary"
        class="btn-header-link"
        >{{ categoryName }}</b-button
      >
    </b-card-header>

    <b-collapse :id="`collapsed-item-${index}`">
      <b-card>
        <div class="footer">
          <ul class="footer_list">
            <li v-for="(item, j) in oneCategoryItem[1]" :key="j"><a :href="getLink(item)"> {{item[1].title}}</a></li>
            <li v-for="(item, j) in oneCategoryItem[2]" :key="j + oneCategoryItem[1].length"><a :href="getLink(item)"> {{item[1].title}}</a></li>

          </ul>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BButton,
  BCollapse,
  VBToggle,
  BCard,
  BCardHeader,
} from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BCollapse,
    BCard,
    BCardHeader,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    oneCategoryItem: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      footerCategoryList: 'GET_FOOTER_CATEGORY_LIST',
    }),
    categoryName() {
      if (this.lang === 'en') {
        return this.footerCategoryList.find((cat) => (cat.code === this.oneCategoryItem[0]))?.nameTranslationEng || '';
      } else {
        return this.footerCategoryList.find((cat) => (cat.code === this.oneCategoryItem[0]))?.nameTranslationHeb || '';
      }
    },
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    getLink(item) {
      if (item[1].link) {
        if (item[1].link.startsWith('http')) {
          return item[1].link;
        }
      } else {
        return `/${item[1].link}`;
      }

      return `/${item[1].slug}`;
    },
  },
};
</script>

<style scoped>
header button.btn {
  text-align: inherit;
}
.card .card-header .btn-header-link.collapsed:after {
  content: '\f107';
}
.card .card-header .btn-header-link:after {
  content: '\f106';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: left;
}
.card {
  margin-bottom: 5px;
}
.card-body, .card .card-header .btn-header-link{
  background: #0161ab;
}
</style>
